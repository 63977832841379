<template>
  <v-navigation-drawer
    v-bind:width="220"
    color="primary"
    mobile-breakpoint="960"
    :right="$vuetify.rtl"
    mini-variant-width="80"
    :expand-on-hover="expandOnHover"
    v-model="drawer"
    app
    id="main-sidebar"
    dark
  >
    <v-list>
      <v-toolbar-title class="align-center d-flex justify-content-between">
        <h2 class="ma-7 mb-4 logo-wrapper">
          <img
            src="../../assets/logo.svg"
            alt=""
            width="150"
            class="logo-full"
          />
          <img
            src="../../assets/logo-small.svg"
            alt="Redline Network"
            height="21"
            class="logo-half"
          />
        </h2>
      </v-toolbar-title>
      <!---USer Area -->
      <v-list-item
        v-for="item in items"
        :key="item.title"
        :to="item.to"
        :active-class="`primary white--text`"
        linkd
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="site-link">{{
            item.title
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!---Sidebar Items -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import {
  VIEW_ADMIN,
  VIEW_USER,
  VIEW_LEAD,
  VIEW_POSTING,
  MANAGE_NOTIFICATION,
  VIEW_FINANCIAL_DASHBOARD,
  VIEW_JANUS_DASHBOARD,
  VIEW_NEWS,
} from "@/constants/permission";
export default {
  name: "Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
  }),
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("setDrawer", val);
      },
    },
  },
  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },
  mounted: function () {
    if (this.user && this.user.permission) {
      this.getItems(this.user);
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === "user/setUser") {
          this.getItems(mutation.payload.data);
        }
      });
    }
  },
  methods: {
    getItems(user) {
      this.items = [
        {
          title: this.$t("commonLayout.dashboard"),
          icon: "mdi-home-outline",
          to: "/dashboard",
        },
      ];
      user.permission.forEach((item) => {
        switch (item.name) {
          case VIEW_LEAD:
            this.items.push({
              title: this.$t("commonLayout.leads"),
              icon: "mdi-account-multiple-outline",
              to: "/pages/leads",
            });
            break;

          case VIEW_ADMIN:
            this.items.push({
              title: this.$t("commonLayout.admins"),
              icon: "mdi-account-outline",
              to: "/pages/admin",
            });
            break;

          case VIEW_USER:
            this.items.push({
              title: this.$t("commonLayout.members"),
              icon: "mdi-account-multiple-outline",
              to: "/pages/members",
            });
            break;

          case VIEW_POSTING:
            this.items.push({
              title: this.$t("commonLayout.posting"),
              icon: "mdi-account-multiple-outline",
              to: "/pages/private/posting",
            });
            break;

          case MANAGE_NOTIFICATION:
            this.items.push({
              title: this.$t("commonLayout.notification"),
              icon: "mdi-bell-outline",
              to: "/pages/notification",
            });
            break;

          case VIEW_FINANCIAL_DASHBOARD:
            // this.items.push({
            //   title: this.$t("commonLayout.financialDashboard"),
            //   icon: "mdi-cash",
            //   to: "/pages/financial-dashboard",
            // });

            this.items.push({
              title: this.$t("commonLayout.duePayment"),
              icon: "mdi-cash",
              to: "/pages/due-payment",
            });
            break;

          case VIEW_JANUS_DASHBOARD:
            this.items.push({
              title: this.$t("commonLayout.janusDashboard"),
              icon: "mdi-account-multiple-outline",
              to: "/pages/janus-dashboard",
            });
            break;

          case VIEW_NEWS:
            this.items.push({
              title: this.$t("commonLayout.news"),
              icon: "mdi-newspaper",
              to: "/pages/news",
            });
            break;
        }
      });
    },
  },
};
</script>
<style lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  border-radius: 0 30px 30px 0;
  .v-navigation-drawer__border {
    display: none;
  }
  .v-list {
    padding: 8px 15px;
  }
  .v-list-item {
    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
    &__title {
      font-size: 15px;
      font-weight: 400;
      text-indent: 0.0892857143em;
      letter-spacing: 1px;
    }
  }
}
.logo-wrapper {
  position: relative;
  img {
    transition: 300ms all;
    + img {
      position: absolute;
      left: 0;
      opacity: 0;
    }
  }
}
.v-navigation-drawer--mini-variant {
  .logo-wrapper img:first-child {
    opacity: 0;
  }
  .logo-wrapper img + img {
    opacity: 1;
  }
}
</style>
